module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-simple-analytics/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"desert-valleys-cu","short_name":"Desert Valleys CU","start_url":"/","background_color":"#FFFFFF","theme_color":"#9e012e","display":"minimal-ui","icon":"static/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"a219ef84b34b340b3a6b526a99b7ad69"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
